/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		Hampton Inspired
		Date:		2nd November 2017
		Author:		Edge Marketing Solutions

===========================================================================*/

	/* [Global Variables] - http://sass-lang.com/guide */

	$color-blue: rgb(18, 109, 181);
	$color-grey: rgb(65, 64, 66);
	$color-brown: rgb(127, 119, 113);

	$font-default: 'Roboto Condensed', sans-serif;

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
		color: $color-grey;

		font-family: $font-default;
		font-weight: 300;
		font-size: 1.1rem;
	}

	a
	{
		-webkit-transition: all 150ms ease-in-out;
		-moz-transition: all 150ms ease-in-out;
		-ms-transition: all 150ms ease-in-out;
		-o-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;

		color: $color-blue;
	}

	h1
	{
		margin: 0 0 30px 0;

		font-size: 4.1rem;
		font-weight: 300;
		color: $color-blue;
	}

	h2
	{
		margin: 30px 0;

		font-weight: 300;
		color: $color-brown;
		font-size: 2.4rem;
	}

	h3
	{
		margin: 30px 0;

		font-weight: 300;
		color: $color-blue;
		font-size: 2.4rem;
	}

	h4
	{
		margin: 30px 0;

		font-weight: 300;
		color: $color-brown;
		font-size: 1.8rem;
	}

	h5
	{
		margin: 30px 0 0 0;

		font-weight: 700;
		color: $color-blue;
		font-size: 1.4rem;
	}

	h6
	{
		margin: 30px 0 0 0;

		font-weight: 700;
		color: $color-brown;
		font-size: 1.4rem;
	}

	img
	{
		max-width: 100%;
	}

	img.svg-responsive
	{
		width: 100% \9;
	}

	/* Generated by Glyphter (http://www.glyphter.com) on Tue Sep 06 2016*/
	@font-face {
		font-family: 'social icons';
		src: url('../fonts/social-icons.eot');
		src: url('../fonts/social-icons.eot?#iefix') format('embedded-opentype'),
		url('../fonts/social-icons.woff') format('woff'),
		url('../fonts/social-icons.ttf') format('truetype'),
		url('../fonts/social-icons.svg#social-icons') format('svg');
		font-weight: normal;
		font-style: normal;
	}
	[class^="social-icon-"]::before, [class*=" social-icon-"]::before {
		display: inline-block;
		font-family: 'social icons';
		font-style: normal;
		font-weight: normal;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.social-icon-square-facebook:before{content:'\0041';}
	.social-icon-square-google-plus:before{content:'\0042';}
	.social-icon-square-linkedin:before{content:'\0043';}
	.social-icon-square-pinterest:before{content:'\0044';}
	.social-icon-square-twitter:before{content:'\0045';}
	.social-icon-square-youtube:before{content:'\0046';}
	.social-icon-circle-facebook:before{content:'\0047';}
	.social-icon-circle-google-plus:before{content:'\0048';}
	.social-icon-circle-linkedin:before{content:'\0049';}
	.social-icon-circle-pinterest:before{content:'\004a';}
	.social-icon-circle-twitter:before{content:'\004b';}
	.social-icon-circle-youtube:before{content:'\004c';}
	.social-icon-rounded-facebook:before{content:'\004d';}
	.social-icon-rounded-google-plus:before{content:'\004e';}
	.social-icon-rounded-linkedin:before{content:'\004f';}
	.social-icon-rounded-pinterest:before{content:'\0050';}
	.social-icon-rounded-twitter:before{content:'\0051';}
	.social-icon-rounded-youtube:before{content:'\0052';}
	.social-icon-square-instagram:before{content:'\0053';}
	.social-icon-rounded-instagram:before{content:'\0054';}

	[class^="social-icon-circle-"]::before, [class*=" social-icon-circle-"]::before {
		border-radius: 100%;
	}

	[class^="social-icon-rounded-"]::before, [class*=" social-icon-rounded-"]::before {
		border-radius: 15%;
	}

	.swiper-container
	{
		width: 100%;
	}

	.btn
	{
		border-radius: 0
	}

	.btn-primary
	{
		background-color: $color-blue;
		border: none;

		color: rgb(255, 255, 255);
	}

		.btn-primary:hover
		{
			background-color: $color-brown;

			color: rgb(255, 255, 255);
		}

	.btn-info
	{
		background-color: transparent;
		border: 1px solid $color-brown;

		color: $color-brown;
	}

		.btn-info:hover
		{
			background-color: $color-brown;
			border: 1px solid $color-brown;

			color: rgb(255, 255, 255);
		}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		h1
		{
			font-size: 3.0rem;
		}

		h2
		{
			font-size: 2.2rem;
		}

		h3
		{
			font-size: 2.2rem;
		}

		h4
		{
			font-size: 1.6rem;
		}

		h5
		{
			font-size: 1.2rem;
		}

		h6
		{
			font-size: 1.2rem;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		h1
		{
			font-size: 2.6rem;
		}

		h2
		{
			font-size: 2.2rem;
		}

		h3
		{
			font-size: 2.2rem;
		}

		h4
		{
			font-size: 1.6rem;
		}

		h5
		{
			font-size: 1.2rem;
		}

		h6
		{
			font-size: 1.2rem;
		}

		//Bootstrap - hide only mobile
		.d-xs-none
		{
			display: none;
		}
	}

	/*=========================================================================*/

	/* [Header Template Styles] */

	.header
	{
		padding: 30px 55px 80px 55px;
		width: 100%;

		background : -moz-linear-gradient(0% 50% 0deg,rgba(18, 109, 181, 1) 0%,rgba(39, 170, 225, 1) 100%);
		background : -webkit-linear-gradient(0deg, rgba(18, 109, 181, 1) 0%, rgba(39, 170, 225, 1) 100%);
		background : -webkit-gradient(linear,0% 50% ,118.04% 50% ,color-stop(0,rgba(18, 109, 181, 1) ),color-stop(1,rgba(39, 170, 225, 1) ));
		background : -o-linear-gradient(0deg, rgba(18, 109, 181, 1) 0%, rgba(39, 170, 225, 1) 100%);
		background : -ms-linear-gradient(0deg, rgba(18, 109, 181, 1) 0%, rgba(39, 170, 225, 1) 100%);
		-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#126DB5', endColorstr='#27AAE1' ,GradientType=0)";
		background : linear-gradient(90deg, rgba(18, 109, 181, 1) 0%, rgba(39, 170, 225, 1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#126DB5',endColorstr='#27AAE1' , GradientType=1);

		color: rgb(255, 255, 255);
	}

	.header .subpage-banner
	{
		width: 100%;
		margin-top: 35px;

		-webkit-box-shadow: 4px 4px 20px 0 rgba(0,0,0,0.4);
		box-shadow: 4px 4px 20px 0 rgba(0,0,0,0.4);
	}

	.header .swiper-container
	{
		width: 100%;
		margin-top: 35px;
		margin-bottom: 50px;

		-webkit-box-shadow: 4px 4px 20px 0 rgba(0,0,0,0.4);
		box-shadow: 4px 4px 20px 0 rgba(0,0,0,0.4);
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px)
	{
		.header .swiper-container
		{
			margin-bottom: 20px;
		}
	}


	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.header
		{
			padding: 30px 55px 45px 55px;

			text-align: center;
		}

			.header .h-logo img
			{
				max-width: 250px;
			}

		.header .swiper-container
		{
			margin-bottom: 20px;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.header
		{
			padding: 35px 10px 35px 10px;

			text-align: center;
		}

		.header .swiper-container
		{
			margin-bottom: 0px;
		}
	}

	/*=========================================================================*/

	/* [Navigation Template Styles] */

	.navbar-light
	{
		padding: 0;
	}

	.navbar-light .navbar-nav
	{
		width: 100%;

		justify-content: flex-end;
	}

	.navbar-expand-md .navbar-nav .nav-link
	{
		padding: 0 1.5rem;

		color: rgb(255, 255, 255);
		font-size: 1.0rem;
	}

	.navbar-light .navbar-nav .show > .nav-link,
	.navbar-light .navbar-nav .active > .nav-link,
	.navbar-light .navbar-nav .nav-link.show,
	.navbar-light .navbar-nav .nav-link.active
	{
		color: rgb(0, 0, 0);
	}

	.navbar-light .navbar-toggler
	{
		width: 100%;

		border-color: rgb(255, 255, 255);

		color: rgb(255, 255, 255);
	}

	.navbar-light .navbar-toggler-icon
	{
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
	}

	/* Large desktop */
	@media (min-width: 1200px) {
		.navbar-nav .dropdown-menu
		{
			left: 50%;
			transform: translateX(-50%);
		}

		.dropdown-item
		{
			padding: 0.25rem 0.5rem;
			text-align: center;
		}
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.navbar-expand-md .navbar-nav .nav-link
		{
			padding: 0 1.3rem;

			font-size: 0.9rem;
		}

		.navbar-nav .dropdown-menu
		{
			left: 50%;
			transform: translateX(-50%);
		}

		.dropdown-item
		{
			padding: 0.25rem 0.5rem;
			text-align: center;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.navbar-light
		{
			margin-top: 30px;
		}

		.navbar-light .navbar-nav
		{
			justify-content: center;
		}

		.navbar-nav .dropdown-menu
		{
			left: 50%;
			transform: translateX(-50%);
		}

		.dropdown-item
		{
			padding: 0.25rem 0.5rem;
			text-align: center;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.navbar-light
		{
			margin-top: 30px;
		}

		.navbar-expand-md .navbar-nav .nav-link
		{
			padding: 1rem 0rem;

			font-size: 1.2rem;
		}

		.dropdown-menu
		{
			text-align: center;
		}
	}

	/*=========================================================================*/

	/* [Page Template Styles] */

	.page
	{
		padding: 60px 55px;
	}

	.page-homepage img
	{
		margin-top: -120px;
	}

		.page-homepage p.lead
		{
			color: $color-blue;
			font-weight: 300;
			font-size: 35px;
		}

	.listing
	{
		margin: 30px 0 30px 0;
		width: 100%;
		padding: 0 40px;
		position: relative;

		text-align: center;
	}

		.listing::before
		{
			content: ' ';
			position: absolute;
			top: 30%;
			right: -20px;
			width: 6px;
			height: 36px;
			z-index: 1;

			background-image: url(../images/icon-seperator.svg);
			background-repeat: no-repeat;
		}

		.listing h5
		{
			margin: 30px 0 20px 0;
		}


	/* Large desktop */
	@media (min-width: 1200px) {
		.home-listings .col-lg-4:last-child .listing::before,
		.home-listings .col-lg-4:nth-child(3n+3) .listing::before
		{
			display: none;
		}
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.page-homepage
		{
			padding: 60px 25px;
		}

		.listing
		{
			padding: 0 10px;
		}

		.home-listings .col-lg-4:last-child .listing::before,
		.home-listings .col-lg-4:nth-child(3n+3) .listing::before
		{
			display: none;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.page-homepage
		{
			padding: 60px 25px;
		}

		.listing
		{
			padding: 0 10px;
		}

		.home-listings .col-lg-4:last-child .listing::before,
		.home-listings .col-lg-4:nth-child(2n+2) .listing::before
		{
			display: none;
		}

		.page-homepage img
		{
			margin-top: 0px;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.page
		{
			padding: 30px 10px;
		}

		.page-homepage img
		{
			margin-top: 0px;
		}

		.listing
		{
			padding: 0;
		}

		.listing::before
		{
			display: none;
		}
	}

	/*=========================================================================*/

	/* [Footer Template Styles] */

	.footer
	{
		padding: 60px 55px;

		color: $color-grey;
		font-size: 0.9rem;
	}

		.footer a
		{
			color: $color-grey;
		}

			.footer a:hover
			{
				color: $color-blue;
			}

		.footer .nav
		{
			justify-content: flex-end;
			align-items: center;
		}

		.footer .social-link
		{
			font-size: 1.5rem;
			line-height: 1.0rem;
			color: $color-blue;
		}

		.footer .btn-primary
		{
			margin: 0 0 40px 0;

			background-color: transparent;
			border: 1px solid $color-blue;

			color: $color-blue;
		}

		.footer .btn-primary:hover
		{
			background-color: $color-brown;

			color: rgb(255, 255, 255);
		}

		.footer .footnotes
		{
			padding-right: 1rem;

			font-size: 0.8rem;
		}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.footer
		{
			text-align: center;
		}

			.footer .nav
			{
				margin: 30px 0 0 0;
				justify-content: center;
			}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.footer
		{
			text-align: center;
			padding: 30px 10px;
		}

			.footer .nav
			{
				margin: 30px 0 0 0;
				display: block;
			}
	}

	/*=========================================================================*/

	/* [Table as row override Styles] */

	table.table-as-row > tbody > tr:before,
	table.table-as-row > tbody > tr:after {
		content: " ";
		display: table;
		clear: both;
	}

	table.table-as-row > tbody > tr {
		height: auto !important;
	}

	table.table-as-row,
	table.table-as-row > tbody,
	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
	}

	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
		margin-right: -15px;
		margin-left: -15px;
	}

	table.table-as-row > tbody > tr > td[class*=col-] {
		display: block;
		height: auto !important;
		margin-bottom: 20px;
	}

	/* col-lg */
	@media (min-width: 1200px) {
		table.table-as-row > tbody > tr > td[class*=col-lg-] {
			float: left;
		}
	}

	/* col-md */
	@media (min-width: 992px) {
		table.table-as-row > tbody > tr > td[class*=col-md-] {
			float: left;
		}
	}

	/* col-sm */
	@media (min-width: 768px) {
		table.table-as-row > tbody > tr > td[class*=col-sm-] {
			float: left;
		}
	}

	/* col-xs */
	table.table-as-row > tbody > tr > td[class*=col-xs-] {
		float: left;
	}